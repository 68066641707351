export const Mail_Vars = {
    fb_link: "http://www.facebook.com/archerandcoestateagents",
    tw_link: "https://twitter.com/",
    sitename: "Archer & Co",
    contact_email: "london@archerandco.com",
    company_phone: "0203 368 8745",
    available_time: "9am - 5pm ,",
    available_days: " Monday - Friday",
    address: "121 Park Lane, Mayfair, London, W1K 7AG",
    primary_color: "#D3AF35"
}

export const Site_Vars = {
    default_currency: "£"
}

export const getWhatsAppURL = number => {
    if (!number) return null
    // remove everything except numbers
    const formatedNumber = number?.replace(/\D/g, "")
    return `https://api.whatsapp.com/send?phone=${formatedNumber}`
}